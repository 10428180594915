<template>
  <div v-loading.fullscreen="busy">
    <div class="audience-creation">
      <strong>
        Created:
      </strong>
      {{ audience.created_at | dateFormat }}
    </div>
    <h3>
      <audience-type-tooltip
        v-if="audience.type"
        :type="audience.type"
      />
      {{ audience.name }}
      <el-tag
        v-if="audience.merged"
        type="info"
        size="small"
        class="merge-tag"
      >
        MERGED
      </el-tag>
      <el-tag
        v-if="outdated(audience.start_date)"
        class="outdated-tag"
        effect="dark"
        size="small"
        type="danger"
      >
        out of date
      </el-tag>
      <el-tag
        v-if="isInternal && audience.id"
        size="small"
      >
        ID: {{ audience.id }}
      </el-tag>
    </h3>
    <div
      v-if="audience.parent && hasPermission('split_publish')"
      class="split"
    >
      Source Audience:
      <router-link :to="{ name: 'audience-detail', params: { id: audience.parent.id }}">
        {{ audience.parent.name }}
      </router-link>
    </div>
    <div class="org-name">
      {{ orgName }}
    </div>
    <el-row class="count-summary">
      <el-col
        class="count"
        :sm="24"
        :md="geoframeBased || pixel || ((reverseAppends || autoPolygon) && audience.meta) ? 8 : 24"
      >
        <div class="label">
          Count
        </div>
        <div class="value">
          <span v-if="audience.count_error">
            <i class="fa fa-exclamation-triangle" />
          </span>
          <span v-else>
            {{ audience.count | numeral }}
          </span>
        </div>
      </el-col>
      <el-col
        v-if="geoframeBased"
        class="count"
        :sm="24"
        :md="8"
      >
        <div class="label">
          # Geoframes
        </div>
        <div class="value">
          {{ audience.geo_count | numeral }}
        </div>
      </el-col>
      <el-col
        v-if="geoframeBased || pixel"
        class="count"
        :sm="24"
        :md="8"
      >
        <div class="label">
          Date Range
        </div>
        <div class="value">
          <div>{{ audience.start_date }}</div>
          <div class="date-to">
            to
          </div>
          <div>{{ audience.end_date }}</div>
        </div>
      </el-col>
      <el-col
        v-if="(reverseAppends || autoPolygon) && audience.meta"
        class="count"
        :sm="24"
        :md="8"
      >
        <div class="label">
          Processed Addresses
        </div>
        <div class="value">
          {{ audience.meta.address_count | numeral }}
        </div>
      </el-col>
      <el-col
        v-if="(reverseAppends || autoPolygon) && audience.meta"
        class="count"
        :sm="24"
        :md="8"
      >
        <div class="label">
          Matched Addresses
        </div>
        <div class="value">
          {{ audience.meta.address_match_count | numeral }}
        </div>
      </el-col>

      <el-col
        v-if="(reverseAppends || autoPolygon) && audience.meta.unmatched_report_signed"
        class="count"
        :sm="24"
        :md="8"
      >
        <div class="label">
          Unmatched Addresses
        </div>
        <div class="value">
          <a :href="audience.meta.unmatched_report_signed">
            <el-button>
              Download Report
            </el-button>
          </a>
        </div>
      </el-col>
    </el-row>

    <div class="details">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          label="Overview"
          name="overview"
        >
          <el-row :gutter="10">
            <el-col
              class="column cards"
              :sm="24"
              :md="18"
            >
              <el-card
                v-if="audience.type === 'DEMOGRAPHICS'"
                shadow="never"
              >
                <div slot="header">
                  <span>Demographic Selections</span>
                </div>
                <div>
                  <demographic-selections
                    :selections="audience.meta.selections"
                    :political-ranges="audience.meta.political_ranges"
                  />
                </div>
              </el-card>
              <el-card
                v-if="audience.type === 'POI'"
                shadow="never"
              >
                <div slot="header">
                  <span>QuickSelect Criteria</span>
                </div>
                <div>
                  <quick-selections
                    :selections="audience.meta.search_criteria"
                  />
                </div>
              </el-card>
              <el-card
                v-if="audience.type === 'PREDICTED_MOVERS'"
                shadow="never"
              >
                <div slot="header">
                  <span>Zipcode Selections</span>
                </div>
                <div>
                  <el-tag
                    v-for="zip in audience.meta.zipcodes"
                    :key="zip"
                  >
                    {{ zip }}
                  </el-tag>
                </div>
              </el-card>
              <el-card
                v-if="audience.type === 'PREDICTED_MOVERS'"
                shadow="never"
              >
                <div slot="header">
                  <span>Month Selected</span>
                </div>
                <div>
                  <el-tag>
                    {{ audience.meta.target_date }}
                  </el-tag>
                </div>
              </el-card>
              <el-card
                v-if="showDownloads"
                shadow="never"
              >
                <div slot="header">
                  <span>Downloads</span>
                </div>
                <div>
                  <downloads :downloads="downloads" />
                </div>
              </el-card>
              <el-card
                v-if="showDwellTimeListing"
                shadow="never"
              >
                <div slot="header">
                  <span>OOH Measurements</span>
                </div>
                <div>
                  <dwell-time-report-listing
                    :downloads="dwellTimeReports"
                    :busy="busy"
                    @delete="handleDeleteDTR"
                  />
                </div>
              </el-card>
              <el-card shadow="never">
                <div slot="header">
                  <span>Jobs</span>
                </div>
                <div>
                  <job-details :jobs="jobs" />
                </div>
              </el-card>
              <el-card shadow="never">
                <div slot="header">
                  <span>Publication Information</span>
                </div>
                <div>
                  <publication-table
                    :schedules="publishSchedule"
                    @schedule-change="handleScheduleChange"
                  />
                </div>
              </el-card>
              <el-card
                v-if="showClones"
                shadow="never"
              >
                <div slot="header">
                  <span>Audience Clones</span>
                </div>
                <div>
                  <clone-table
                    :clones="clones"
                    @clear-clones="handleCloneCancellation"
                  />
                </div>
              </el-card>
            </el-col>
            <el-col
              class="column cards"
              :sm="24"
              :md="6"
            >
              <action-menu
                :actions="actions"
                :show-archive-restore="hasArchivePerms"
                :item="audience"
                mode="buttons"
                raised
                @action="handleAction"
              />
              <el-card shadow="never">
                <div slot="header">
                  <span>Tags</span>
                </div>
                <div class="tag-list">
                  <tag-picker
                    :tags="audience.tags"
                    tag-size="medium"
                    @add-tag="handleAddTag"
                    @remove-tag="handleRemoveTag"
                  />
                </div>
              </el-card>
              <el-card
                v-if="audience.merged"
                shadow="never"
                class="merge-sources"
              >
                <div slot="header">
                  <span>Source Audiences</span>
                </div>
                <ul>
                  <li
                    v-for="item of audience.merge_sources"
                    :key="item.id"
                  >
                    <router-link :to="`/audiences/library/${item.id}`">
                      {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </el-card>
              <el-card
                v-if="showSourceFiles"
                shadow="never"
              >
                <div slot="header">
                  <span>Source Files</span>
                </div>
                <div class="small">
                  <h3>Reminder:</h3>
                  <p>Audience Source Files contain <strong>unhashed device IDs.</strong><br>They are for internal use <strong><em>ONLY!</em></strong></p>
                </div>
                <ul>
                  <li
                    v-for="file of audience.files"
                    :key="file.id"
                  >
                    <a
                      :href="file.signed_url"
                      target="_blank"
                    >{{ file.path }}</a>
                  </li>
                </ul>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane
          v-if="!deviceAudience"
          label="Geoframes"
          name="geoframes"
        >
          <div v-if="audience.id">
            <el-row :gutter="20">
              <el-col
                :xs="24"
                :md="19"
              >
                <audience-creation-table
                  ref="geoTable"
                  :audience-id="audience.id"
                  :audience-type="audience.type"
                  mode="split"
                  @selection-change="selectionChange"
                />
              </el-col>
              <el-col
                :xs="24"
                :md="5"
              >
                <audience-creation-form
                  mode="split"
                  :selections="geoframes"
                  @reset="handleReset"
                />
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import {
  dateFormat,
  isDeviceAudience,
} from '../../../helpers';
import { audience as audienceApi } from '@/adonis-api';
import TagPicker from '@/components/global/TagPicker.vue';
import JobDetails from '@/components/details/JobDetails.vue';
import Downloads from '@/components/details/Downloads.vue';
import DemographicSelections from '@/components/demographics/DemographicSelections.vue';
import QuickSelections from '../../audience/poi/Selections.vue';
import CloneTable from '@/components/details/CloneTable.vue';
import PublicationTable from '@/components/details/PublicationTable.vue';
import ActionMenu from '@/components/global/ActionMenu.vue';
import { getAudienceMenu } from '@/menus';
import downloadFile from '@/helpers/download-file';
import DelayedAction from '@/helpers/delayed-action';
import AudienceCreationForm from '../../audience/AudienceCreationForm';
import AudienceCreationTable from '../../audience/AudienceCreationTable';
import AudienceTypeTooltip from '@/components/global/AudienceTypeTooltip';
import DwellTimeReportListing from '@/components/audience/DwellTimeReportListing.vue';
import { dwellDownloadType } from '@/constants';
import jobWatcher from '@/mixins/job-watcher';

export default {
  name: 'PageAudienceDetail',
  components: {
    ActionMenu,
    AudienceCreationForm,
    AudienceCreationTable,
    AudienceTypeTooltip,
    CloneTable,
    DemographicSelections,
    Downloads,
    JobDetails,
    PublicationTable,
    QuickSelections,
    TagPicker,
    DwellTimeReportListing,
  },

  filters: {
    dateFormat(value) {
      return dateFormat({
        date: value,
        formatOverride: 'M/D/YYYY h:mma',
      });
    },
  },
  mixins: [jobWatcher],

  data() {
    return {
      actions: getAudienceMenu(this),
      activeTab: 'overview',
      audience: {},
      busy: false,
      downloads: [],
      dwellTimeReports: [],
      geoframes: [],
      jobs: [],
      clones: [],
      publishSchedule: {},
    };
  },

  computed: {
    ...mapGetters('mqtt', ['getJobs']),
    ...mapGetters('user', ['hasPermission', 'isInternal']),
    ...mapGetters('settings', ['rangeStart', 'rangeEndDate', 'journeyGeoframeLimit']),

    audienceJobs() {
      return this.getJobs(this.jobs.map(j => j.id));
    },

    autoPolygon() {
      return this.audience.type === 'AUTO_POLYGON';
    },

    deviceAudience() {
      return isDeviceAudience(this.audience.type);
    },

    hasArchivePerms() {
      return this.hasPermission('archive_audiences');
    },

    geoframeBased() {
      return !isDeviceAudience(this.audience.type);
    },

    orgName() {
      return _get(this.audience.organization, 'name');
    },

    pixel() {
      return this.audience.type === 'PIXEL';
    },

    reverseAppends() {
      return this.audience.type === 'REVERSE_APPENDS';
    },

    showClones() {
      return !isDeviceAudience(this.audience.type);
    },

    showSourceFiles() {
      return this.isInternal &&
             Array.isArray(this.audience.files) &&
             this.audience.files.length > 0;
    },

    showDownloads() {
      return this.downloads.length > 0;
    },

    showDwellTimeListing() {
      return this.dwellTimeReports.length > 0;
    },
  },

  watch: {
    '$route.params.id': {
      handler(val) {
        this.init();
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.stopSub && this.stopSub();
  },

  mounted() {
    this.$root.$on('clone-scheduled', () => this.fetchCloneSchedule());
  },

  methods: {
    async handleAction(command) {
      // Check for invalid date range first
      const earliestDate = this.rangeStart.format('YYYY-MM-DD');
      const latestDate = moment(this.rangeEndDate).format('YYYY-MM-DD');
      let response, delayedAction;
      if (
        this.audience &&
        (this.audience.start < earliestDate ||
          this.audience.end > latestDate) &&
        this.audience.historical
      ) {
        if (['publish', 'extend'].includes(command)) {
          this.$store.dispatch('audience/showDialog', [
            {
              audience: this.audience,
              dialog: 'dateFixer',
            },
            {
              audience: this.audience,
              dialog: command,
            },
          ]);

          return;
        } else if (command === 'download') {
          // This is ok, if the permissions are correct
          // FIXME:
          // const org = Organizations.findOne({
          //   organizationName: audience.organizationName,
          // });
          // if (!(this.hasHistoricalAccess && (org && org.historicalAccess))) {
          //   this.chosenAudience = audience;
          //   this.originalChoice = command;
          //   this.showDialog = 'dateFixer';
          //   return;
          // }
        } else if (command === 'audienceReports') {
          this.$notify.warning('Cannot run Insight Report on historical data.');
          return;
        }
      }

      switch (command) {
        case 'admin':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'admin',
          });
          break;

        case 'audienceReports':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'audienceReports',
          });
          break;

        case 'viewEnhancedReports': {
          const queryParams = new URLSearchParams({
            reportId: this.audience.id,
            orientation: 'landscape',
          }).toString();
          const resolvedQuery = JSON.parse('{"' + decodeURI(queryParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

          const reportRoute = this.$router.resolve({
            path: '/audience/reports/title_page',
            query: resolvedQuery,
          });

          window.open(reportRoute.href, '_blank');
          break;
        }
        case 'archive':
          this.busy = true;

          try {
            await audienceApi.delete(this.audience.id);
            this.$router.push('/audiences/library/list');
          } catch (e) {
            this.$notify.error({
              message:
                'There was a problem archiving this audience. The issue has been reported.',
            });
            this.$reportError(e);
          } finally {
            this.busy = false;
          }
          break;

        case 'clone':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'clone',
          });
          break;

        case 'download':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'download',
          });
          break;

        case 'dwell-report-download':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'dwell-report-download',
          });
          break;

        case 'edit':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'edit',
          });
          break;

        case 'journeyReportGenerate':
          response = await audienceApi.requestJourneyReport(this.audience.id);
          if (response.success) {
            this.$notify.info(response.message);
            if (response.data?.job) {
              this.watchJob(response.data.job.id);
              this.audience.journey_job.push({ id: response.data.job.id });
            }
          } else {
            this.$notify.error(response.message);
          }
          break;

        case 'journeyReportView':
          this.$router.push(`/audience/journey/${this.audience.uuid}`);
          break;

        case 'exportGeoframeInfo':
          try {
            (delayedAction = new DelayedAction(() => {
              this.$message('This might take a while, stand by...');
            }, 1500)).run();

            response = await audienceApi.exportGeoframeInfo(this.audience.id);
            delayedAction.cancel();

            downloadFile(response, {
              filename: `${this.audience.name}_geoframes.csv`,
              mimeType: 'text/csv',
              prettify: false,
            });
          } catch (e) {
            this.$reportError(e);
          }

          break;

        case 'exportGeojson':
          try {
            await this.$confirm(
              `Are you sure you want to export the audience "${
                this.audience.name
              }" as geojson?`,
            );

            (delayedAction = new DelayedAction(() => {
              this.$message('This might take a while, stand by...');
            }, 1500)).run();

            response = await audienceApi.exportGeojson(this.audience.id);
            delayedAction.cancel();

            downloadFile(response, {
              filename: `${this.audience.name}.geojson`,
              mimeType: 'application/vnd.geo+json',
              prettify: false,
            });

            break;
          } catch (e) {
            this.$reportError(e);
          }

          break;

        case 'extend':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'extend',
          });
          break;

        case 'publish':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'publish',
          });
          break;

        case 'reprepare':
          response = await audienceApi.reprepare(this.audience.id);
          this.$message(response.message);
          break;

        case 'generateProposal':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'proposal',
          });
          break;

        case 'createTradeArea':
          this.$store.dispatch('audience/showDialog', {
            audience: this.audience,
            dialog: 'tradeArea',
          });
          break;
        default:
          break;
      }
    },

    async fetchCloneSchedule() {
      const response = await audienceApi.getCloneSchedule(
        this.$route.params.id,
      );

      this.clones = response.data.schedule;
    },

    async fetchPublishSchedule() {
      const response = await audienceApi.getPublishSchedule(
        this.$route.params.id,
      );

      this.publishSchedule = _groupBy(
        _sortBy(response.data.schedule, 'scheduled'),
        'uuid',
      );
    },

    async init() {
      this.audience = {};
      this.busy = true;
      this.downloads = [];
      this.dwellTimeReports = [];
      this.geoframes = [];
      this.jobs = [];
      this.clones = [];
      this.publishSchedule = {};

      try {
        const audienceId = this.$route.params.id;

        this.audience = await audienceApi.findOne(audienceId);
        this.busy = false;
        this.jobs = await audienceApi.getJobs(audienceId);
        const response = await audienceApi.getAvailableDownloads(audienceId);
        this.downloads = response.downloads.filter(i => i.type !== dwellDownloadType);
        this.dwellTimeReports = response.downloads.filter(i => i.type === dwellDownloadType);

        this.fetchCloneSchedule();
        this.fetchPublishSchedule();
      } catch (e) {
        this.busy = false;
        this.$notify.error({
          message:
            'There was a problem fetching audience information. This issue has been reported.',
        });
        this.$reportError(e);
      }

      this.stopSub = this.$store.subscribeAction(action => {
        if (
          action.type === 'event' &&
          action.payload.eventType === 'SAVED_AUDIENCE'
        ) {
          const { audience } = action.payload;
          this.audience = audience;
        }
      });
    },

    handleDeleteDTR(id) {
      this.dwellTimeReports = this.dwellTimeReports.filter((d) => d.id !== id);
    },

    selectionChange(selections) {
      this.geoframes = selections;
    },

    async handleAddTag(name) {
      const response = await audienceApi.addTag(this.audience.id, { name });

      this.audience.tags.push(response.data);
      this.showSaveMsg();
    },

    async handleRemoveTag(tagId) {
      await audienceApi.removeTag(this.audience.id, tagId);

      const index = this.audience.tags.findIndex(tag => tag.id === tagId);

      index >= 0 && this.audience.tags.splice(index, 1);
      this.showSaveMsg();
    },

    handleReset() {
      this.$refs.geoTable.clearSelection();
    },

    handleScheduleChange() {
      this.fetchPublishSchedule();
    },

    async handleCloneCancellation() {
      this.busy = true;
      const response = await audienceApi.cancelCloneSchedule(
        this.audience.id,
      );
      this.busy = false;
      this.clones = response.data.schedule;
    },

    outdated(startDate) {
      if (startDate) {
        return moment(startDate).isBefore(this.rangeStart);
      }

      return false;
    },

    publishCollapseTitle(schedules) {
      const anySched = schedules[0];
      const partnerName = anySched.credentials.partner.name;
      const extSuffix = anySched.extension ? ' (extended)' : '';
      let recurConfig = '';

      if (schedules.length > 1) {
        const { config } = anySched;
        recurConfig =
          ` - every ${config.frequency.join(' ')} from ` +
          `${config.from} to ${config.until}`;
      }

      return `${partnerName}${extSuffix}${recurConfig}`;
    },

    showSaveMsg() {
      this.$message({
        message: 'Audience updated.',
        type: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';
.el-tag {
  font-size: 11px;
  padding: 3px;
  line-height: 12px;
  height: 20px;
  margin: 0 5px 5px 0;
}
.org-name {
  color: #666;
  font-size: 0.9em;
  margin-top: -1em;
}

.count-summary {
  border-bottom: 1px solid #ddd;
  margin: 0 auto;
  max-width: 860px;
  padding-bottom: 1em;
}

.count {
  color: #444;
  margin-top: 1.5em;
  text-align: center;

  .label {
    color: #888;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
  }

  .value {
    font-size: 2em;
  }

  .date-to {
    font-size: 0.25em;
    text-transform: uppercase;
  }
}

.details {
  margin-top: 2em;
}

.column:not(:last-of-type) {
  margin-bottom: 10px;
}

.cards > div:not(:last-of-type) {
  margin-bottom: 10px;
}

.split {
  margin-top: -1em;
  margin-bottom: 1.5em;
  font-size: .75em;
  color: #888;
  a {
    text-decoration: none;
    color: darken($--color-primary, 30%);

    &:hover {
      color: $--color-primary;
      text-decoration: underline;
    }
  }
}

.audience-creation {
  font-size: .8rem;
  float: right;
}

.merge-sources {
  ul {
    margin: 0;
  }
  li {
    padding: .5em 0 0 0;
  }
}

.merge-tag {
  margin-right: .5em;
}

.outdated-tag {
  text-transform: uppercase;
  margin-right: .5em;
}

.small {
  background: #fcc;
  padding: 1em;
  font-size: .8rem;
  h3 {
    margin: 0;
  }
  p {
    margin-top: .25em;
  }
}
</style>

<template>
  <div>
    <div v-if="selections.filters.state.length > 0">
      <h3>State</h3>
      <el-tag
        v-for="(state, index) of selections.filters.state"
        :key="'state-' + index"
      >
        {{ state }}
      </el-tag>
    </div>
    <div v-if="selections.filters.city.length > 0">
      <h3>City</h3>
      <el-tag
        v-for="(city, index) of selections.filters.city"
        :key="'city-' + index"
      >
        {{ city }}
      </el-tag>
    </div>
    <div v-if="selections.filters.zip.length">
      <h3>Zipcodes</h3>
      <el-tag
        v-for="(zip, index) of selections.filters.zip"
        :key="'zip-' + index"
      >
        {{ zip }}
      </el-tag>
    </div>
    <div v-if="selections.filters.category.length > 0">
      <h3>Category</h3>
      <el-tag
        v-for="(category, index) of selections.filters.category"
        :key="'category-' + index"
      >
        {{ category }}
      </el-tag>
    </div>
    <div v-if="selections.filters.business?.length > 0">
      <h3>Business</h3>
      <el-tag
        v-for="(business, index) of selections.filters.business"
        :key="'business-' + index"
      >
        {{ business }}
      </el-tag>
    </div>
    <div v-if="selections.filters.name?.length > 0">
      <h3>Business</h3>
      <el-tag
        v-for="(business, index) of selections.filters.name"
        :key="'business-' + index"
      >
        {{ business }}
      </el-tag>
    </div>
    <div v-if="selections.search">
      <h3>Search Term</h3>
      <el-tag>{{ selections.search }}</el-tag>
    </div>
    <div v-if="selections.specific || (typeof selections.specific == 'undefined' && selections.ids.length > 0)">
      <h3>Notes</h3>
      <ul>
        <li>
          Specific results were selected
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selections: {
      type: Object,
      default: () => {
        return {
          choice: '',
          ids: [],
          search: '',
          filters: {
            business: [],
            category: [],
            state: [],
            city: [],
            zip: [],
          },
        };
      },
    },
  },
  computed: {
    searchType() {
      if (this.selections.choice === 'business') {
        return 'Business Name';
      }
      return 'Category';
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 14px;
  text-decoration: underline;
  margin: 0 0 5px;
}
h4 {
  font-size: 11px;
  text-decoration: underline;
  margin: 0 0 5px;
}
ul {
  font-size:11px;
  margin: 0 0 5px;
}
.el-tag {
  font-size: 11px;
  padding: 3px;
  line-height: 12px;
  height: 20px;
  margin: 0 5px 5px 0;
}
</style>

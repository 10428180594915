<template>
  <div>
    <div v-if="jobs.length > 0">
      <div
        v-for="job in jobs"
        :key="job.id"
        :class="jobStatus(job)"
      >
        <div class="header-row">
          <div class="label">
            {{ job.label || job.payload.label }}
          </div>
          <div v-if="isInternal">
            <el-tag
              class="id-tag"
              size="small"
            >
              ID: {{ job.id }}
            </el-tag>
            <el-button
              size="mini"
              @click="handleViewJob(job)"
            >
              View Job
            </el-button>
          </div>
        </div>
        <div class="dates">
          <div>
            Created
            <relative-time :date="job.created_at" />
          </div>
          <div>
            {{ job.status === 'COMPLETE' ? 'Completed' : 'Updated' }}
            <relative-time :date="job.updated_at" />
          </div>
          <div v-if="job.status === 'COMPLETE'">
            Total Job Execution Time: {{ jobExecutionTime(job) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="no-jobs"
    >
      No current jobs.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import RelativeTime from '../global/RelativeTime.vue';

export default {
  name: 'JobDetails',
  components: { RelativeTime },
  props: {
    jobs: { type: Array, default: () => [] },
  },

  computed: {
    ...mapGetters('user', ['isInternal']),
  },

  methods: {
    handleViewJob(job) {
      this.$router.push(`/jobs/${job.id}`);
    },

    jobExecutionTime(job) {
      return moment(job.updated_at).from(moment(job.created_at), true);
    },

    jobStatus(job) {
      return {
        job: true,
        complete: job.status === 'COMPLETE',
        processing: job.status !== 'COMPLETE' && job.status !== 'FAILED',
        failed: job.status === 'FAILED',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.job {
  padding-left: 0.6em;
  border-left-width: 3px;
  border-left-style: solid;

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }

  &.complete {
    border-left-color: $--color-success;
  }

  &.processing {
    border-left-color: #ccc;
  }

  &.failed {
    border-left-color: $--color-danger;
  }

  .header-row {
   display: flex;
  }

  .label {
    flex: 1;
  }

  .type {
    color: #888;
    font-size: 0.7em;
    font-weight: bold;
  }

  .dates {
    color: #888;
    font-size: 0.8em;
    font-weight: bold;
    margin-left: 1.2em;
    margin-top: 0.4em;

    > div:not(:last-of-type) {
      margin-bottom: 0.2em;
    }
  }
}

.no-jobs {
  color: #888;
}

.id-tag {
  font-weight: bold;
  margin-right: 0.4em;
}
</style>

export default function(vm) {
  return Object.freeze([
    {
      command: 'deleteSchedule',
      icon: 'trash',
      label: 'Delete Schedule',
      showAction: () => true,
    },
    {
      command: 'extendSchedule',
      icon: 'calendar',
      label: 'Extend Schedule',
      showAction: () => true,
    },
    {
      command: 'cancelSchedule',
      icon: 'ban',
      label: 'Cancel Remaining Items',
      showAction: () => true,
    },
  ]);
}

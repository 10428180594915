<template>
  <div>
    <div
      v-for="download in downloads"
      :key="download.id"
      class="download"
    >
      <div class="file-icon">
        <i
          class="far fa-2x"
          :class="fileIcon(download)"
        />
      </div>
      <div class="download-button">
        <el-button
          icon="fa fa-download"
          size="mini"
          type="info"
          @click="downloadFile(download)"
        />
      </div>
      <div class="download-type">
        {{ getDownloadType(download.type) }}
        <span v-if="fileIcon(download) === 'fa-file-archive'">(Chunked)</span>
        <span style="color:red"> {{ hashedIndicator(download.hashed) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash';
import { downloadTypes } from '@/constants';

export default {
  name: 'Downloads',
  props: {
    downloads: { type: Array, default: () => [] },
  },

  methods: {
    downloadFile(download) {
      if (!_get(download, 'file.signed_url')) {
        this.$notify.error({
          title: 'Missing Download',
          message: 'This download seems to be missing. This problem has been reported.',
        });
        this.$reportError(new Error('User requested missing download'), {
          download,
        });

        return;
      }

      window.location.href = download.file.signed_url;
    },
    fileIcon(download) {
      const extension = download.file.path.split('.');
      if (extension[extension.length - 1] === 'zip') {
        return 'fa-file-archive';
      }
      return 'fa-file-csv';
    },
    getDownloadType(downloadType) {
      const type = downloadTypes.find(type => type.id === downloadType);

      return type ? type.label : 'Unknown Type';
    },
    hashedIndicator(hashed) {
      return (!hashed ? '[Unhashed]' : '');
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  align-items: center;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 0.7em;
  }

  .download-button {
    margin: 0 0.8em;
  }
}
</style>

<template>
  <span :title="date | dateString({ includeTime: true })">
    {{ relativeDate }}
  </span>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    date: { type: [Date, String], required: true },
  },

  computed: {
    relativeDate() {
      return moment(this.date).fromNow();
    },
  },
};
</script>

<style>

</style>

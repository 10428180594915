import moment from 'moment';
import { rangeStart } from '../store/modules/settings';
import { isDeviceAudience, geoframeLimit } from '@/helpers';
import store from '../store';

const isHistorical = audience =>
  moment(audience.start_date).isBefore(rangeStart);

export default function(vm) {
  return Object.freeze([
    {
      command: 'viewDetails',
      icon: 'clipboard-list',
      label: 'View Details',
      menuOnly: true,
    },
    {
      command: 'edit',
      icon: 'edit',
      label: 'Edit Name & Tags',
      permissions: {
        all: ['edit_audiences'],
      },
    },
    {
      command: 'clone',
      icon: 'clone',
      label: 'Clone',
      permissions: {
        all: ['clone_audiences'],
      },
      showAction: audience => !isDeviceAudience(audience.type),
      disabled: audience => audience.count_pending,
    },
    {
      command: 'download',
      icon: 'download',
      label: 'Download',
      permissions: {
        all: [/^download_/],
      },
      disabled: audience => (audience.count_pending || (isHistorical(audience) && !vm.isInternal)),
    },
    {
      command: 'publish',
      icon: 'paper-plane',
      label: 'Publish',
      permissions: {
        all: [/^publish_/],
      },
      disabled: audience => audience.count_pending || isHistorical(audience),
    },
    {
      command: 'audienceReports',
      icon: 'chart-pie',
      label: 'Generate/Share Reports',
      disabled: audience => audience.count_pending || isHistorical(audience),
    },
    {
      command: 'journeyReportGenerate',
      icon: 'route',
      label: 'Generate Journey Report',
      permissions: {
        all: ['generate_journey'],
      },
      showAction: audience => !isDeviceAudience(audience.type) && !audience.journey_report_url,
      disabled: audience => audience.geo_count < 2 || audience.geo_count > vm.journeyGeoframeLimit || audience.journey_job?.length > 0,
    },
    {
      command: 'journeyReportView',
      icon: 'route',
      label: 'View Journey Report',
      showAction: audience => !isDeviceAudience(audience.type) && audience.journey_report_url,
    },
    {
      command: 'dwell-report-download',
      icon: 'download',
      label: 'Generate OOH Measurement',
      permissions: {
        all: ['download_dwell'],
      },
      showAction: audience => !isDeviceAudience(audience.type),
      disabled: audience => (audience.count_pending || (isHistorical(audience) && !vm.isInternal)),
    },
    {
      command: 'generateProposal',
      icon: 'scroll',
      label: 'Generate Campaign Proposal',
      permissions: {
        all: ['proposals'],
      },
      disabled: audience => audience.count_pending || isHistorical(audience),
    },
    {
      command: 'createTradeArea',
      icon: 'bullseye',
      label: 'Create Trade Area Audience',
      permissions: {
        all: ['create_trade_area_audience'],
      },
      disabled: audience => audience.count_pending || isHistorical(audience) || geoframeLimit(audience.geo_count, store.getters['settings/tradeAreaGeoframeLimit']),
      showAction: audience => !isDeviceAudience(audience.type),
    },
    {
      command: 'exportGeoframeInfo',
      icon: 'file-export',
      label: 'Export Geoframe Details',
      showAction: audience => !isDeviceAudience(audience.type),

    },
    {
      command: 'exportGeojson',
      icon: 'file-export',
      label: 'Export GeoJSON',
      permissions: {
        all: ['geojson_export_audiences'],
      },
      showAction: audience => !isDeviceAudience(audience.type),
    },
  ]);
}

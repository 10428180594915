export default function(vm) {
  return Object.freeze([
    {
      command: 'prepAttribution',
      icon: 'cogs',
      label: 'Prepare Attribution Report',
      showAction: report =>
        vm.canPrepAttribution && report.attribution_status === 'NOT_STARTED' &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
    {
      command: 'viewAttribution',
      icon: 'download',
      label: 'View Attribution Report',
      showAction: report =>
        vm.canViewAttribution && report.attribution_status === 'COMPLETE',
    },
    {
      command: 'viewEnhancedAttribution',
      icon: 'download',
      label: 'View Enhanced Report',
      showAction: report =>
        vm.canViewEnhancedAttribution && report.attribution_status === 'COMPLETE' && report.has_enhanced_permission,
    },
    {
      command: 'copyAttributionLink',
      icon: 'clipboard-list',
      label: 'Copy Attribution Report Link',
      showAction: report =>
        vm.canViewAttribution && report.attribution_status === 'COMPLETE',
    },
    {
      command: 'editReportName',
      icon: 'edit',
      label: 'Edit Attribution Name & Tags',
      showAction: () =>
        vm.canPrepAttribution,
    },
    {
      command: 'clone',
      icon: 'clone',
      label: 'Clone Attribution Report',
      showAction: report => vm.canPrepAttribution && report.attribution_status === 'COMPLETE',
    },
    {
      command: 'createResponderAudience',
      icon: 'street-view',
      label: 'Create Responders Audience',
      showAction: report =>
        vm.canCreateResponderAudience && report.attribution_status === 'COMPLETE' && vm.validDateRange(report) &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
    {
      command: 'prepJourney',
      icon: 'cogs',
      label: 'Prepare Journey Report',
      showAction: report =>
        vm.canDownloadJourneys && report.journey_status !== 'DISABLED' && report.journey_status === 'NOT_STARTED' && report.attribution_status === 'COMPLETE' &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
    {
      command: 'downloadJourney',
      icon: 'download',
      label: 'Download Journey Report',
      showAction: report =>
        vm.canDownloadJourneys && report.journey_status === 'COMPLETE',
    },
    {
      command: 'prepLocation',
      icon: 'cogs',
      label: 'Prepare Locations Report',
      showAction: report =>
        vm.canDownloadLocations && report.location_status === 'NOT_STARTED' && report.attribution_status === 'COMPLETE' &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
    {
      command: 'downloadLocation',
      icon: 'download',
      label: 'Download Locations Report',
      showAction: report =>
        vm.canDownloadLocations && report.location_status === 'COMPLETE',
    },
    {
      command: 'prepHousehold',
      icon: 'cogs',
      label: 'Prepare HH Locations Report',
      showAction: report =>
        vm.canDownloadHHLocations && report.household_status === 'NOT_STARTED' && report.attribution_status === 'COMPLETE' &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
    {
      command: 'downloadHousehold',
      icon: 'download',
      label: 'Download HH Locations Report',
      showAction: report =>
        vm.canDownloadHHLocations && report.household_status === 'COMPLETE',
    },
    {
      command: 'downloadPDF',
      icon: 'download',
      label: 'Download PDF Report',
      showAction: report =>
        vm.canViewEnhancedAttribution && report.attribution_status === 'COMPLETE'  && report.has_enhanced_permission,
    },
    {
      command: 'generateDeviceFiles',
      icon: 'download',
      label: 'Generate Device Files',
      showAction: report =>
        vm.canGenerateDeviceFiles && report.attribution_status === 'COMPLETE' &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
    {
      command: 'generatePostCampaign',
      icon: 'scroll',
      label: 'Create Post Campaign Report',
      permissions: {
        all: ['post_campaign'],
      },
      showAction: report =>
        report.attribution_status === 'COMPLETE' &&
        report.__meta__.deleted_source_audiences_count === '0' &&
        report.__meta__.deleted_destination_audiences_count === '0' &&
        report.__meta__.deleted_source_publications_count === '0' &&
        report.__meta__.deleted_destination_publications_count === '0' &&
        report.__meta__.outdated_source_audiences_count === '0' &&
        report.__meta__.outdated_destination_audiences_count === '0',
    },
  ]);
}

<template>
  <div>
    <el-table
      :data="rowData"
      stripe
    >
      <el-table-column type="expand">
        <template slot-scope="{ row, $index }">
          <div>
            <div
              v-if="canExtend($index)"
            >
              <el-button @click="handleExtend($index)">
                Extend Schedule
              </el-button>
            </div>
            <div
              v-if="row.futurePubScheds.length > 0"
            >
              <el-button
                size="small"
                type="text"
                @click="handleCancelSched($index)"
              >
                Cancel Republish Schedule
              </el-button>
            </div>
          </div>
          <div class="pub-tables">
            <div class="future-pubs">
              <div class="table-header">
                Scheduled Publications
              </div>
              <el-table
                :data="row.futurePubScheds"
                size="mini"
                stripe
              >
                <el-table-column
                  label="Scheduled"
                  prop="scheduled"
                />
              </el-table>
            </div>

            <div class="past-pubs">
              <div class="table-header">
                Past Publications
              </div>
              <el-table
                :data="row.pastPubs"
                size="mini"
                stripe
              >
                <el-table-column label="Publish Title">
                  <template slot-scope="{ row: pub }">
                    {{ pub.publish_title }}
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isInternal"
                  label="Publication ID"
                >
                  <template slot-scope="{ row: pub }">
                    {{ pub.id }}
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isInternal"
                  label="Schedule ID"
                >
                  <template slot-scope="{ row: pub }">
                    {{ pub.publish_schedule_id }}
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isInternal"
                  label="Target Device"
                >
                  <template slot-scope="{ row: pub }">
                    {{ deviceType(pub.device_type) }}
                  </template>
                </el-table-column>
                <el-table-column label="Published">
                  <template slot-scope="{ row: pub }">
                    {{ pub.completed | dateString }}
                  </template>
                </el-table-column>
                <el-table-column label="Count">
                  <template slot-scope="{ row: pub }">
                    {{ pub.publish_count | numeral }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Execution Time"
                  prop="executionTime"
                />
                <el-table-column
                  align="center"
                  label="Status"
                >
                  <template slot-scope="{ row: pub }">
                    <el-tag
                      :type="statusColor(pub)"
                      size="mini"
                    >
                      {{ pub.status }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Publication"
        prop="partner"
      />
      <el-table-column
        label="Last Published"
        prop="lastPublished"
        width="120"
      />
      <el-table-column
        label="Target Device"
        prop="targetDevice"
        width="120"
      />
      <el-table-column
        label="Last Count"
        prop="lastCount"
        width="100"
      >
        <template slot-scope="{ row }">
          {{ row.lastCount | numeral }}
        </template>
      </el-table-column>
      <el-table-column
        label="Remaining Republishes"
        prop="scheduledRepublishes"
        width="120"
      />
      <el-table-column
        label="Recur Type"
        width="130"
      >
        <template slot-scope="{ row }">
          {{
            row.futurePubScheds.length > 0
              ? row.futurePubScheds[0].recur_type
              : 'N/A'
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="Lookback"
        width="140"
      >
        <template slot-scope="{ row }">
          {{ getLookback(row) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Next Publish"
        prop="nextPublish"
        width="120"
      />
      <el-table-column
        label="Created By"
        prop="createdBy"
      />
      <el-table-column label="Credential Set Name">
        <template slot-scope="{ row }">
          {{ row.credential }}
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="Extend Publication Schedule"
      :visible.sync="showExtendDialog"
    >
      <el-date-picker
        v-model="newUntilDate"
        :picker-options="pickerOptions"
      />
      <span slot="footer">
        <el-button @click="showExtendDialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="handleCommitExtend"
        >
          Extend Schedule
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { dateToString } from '@/helpers';
import { audience as audienceApi } from '@/adonis-api';

import { upperCaseFirst } from 'upper-case-first';

export default {
  props: {
    schedules: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      currentUntil: null,
      newUntilDate: null,
      pickerOptions: {
        disabledDate: date => {
          return moment(date).isSameOrBefore(moment(this.currentUntil));
        },
      },
      showExtendDialog: false,
    };
  },

  computed: {
    ...mapGetters('user', ['isInternal']),

    rowData() {
      const data = [];

      for (const schedule in this.schedules) {
        const pubScheds = this.schedules[schedule];
        const row = {};

        row.partner =
          pubScheds[0].credentials.partner.name +
          (pubScheds[0].extension ? ` (${upperCaseFirst(pubScheds[0].extension_type.toLowerCase())} Extension)` : '');
        row.publishUntil = pubScheds[pubScheds.length - 1].scheduled;

        const nextPublication = pubScheds.find(p => !p.publications.length);

        row.audienceId = pubScheds[0].audience_id;
        row.uuid = pubScheds[0].uuid;
        row.credential = pubScheds[0].credentials.credentials ? pubScheds[0].credentials.credentials.name : 'Default';
        row.nextPublish = nextPublication ? nextPublication.scheduled : 'Never';
        row.futurePubScheds = pubScheds.filter(p => !p.publications.length);
        row.pastPubs = pubScheds
          .filter(p => p.publications.length)
          .reduce((all, current) => all.concat(current.publications), [])
          .map(p => ({
            ...p,
            executionTime: p.completed
              ? moment(p.completed).from(
                p.started,
                true,
              )
              : 'N/A',
          }));

        row.lastPublished =
          row.pastPubs.length > 0
            ? dateToString(row.pastPubs[row.pastPubs.length - 1].updated_at)
            : 'Never';
        row.lastCount =
          row.pastPubs.length > 0
            ? row.pastPubs[row.pastPubs.length - 1].publish_count
            : 'N/A';

        row.scheduledRepublishes = row.futurePubScheds.length;
        row.createdBy = pubScheds[0].user ? `${pubScheds[0].user.first_name} ${
          pubScheds[0].user.last_name
        }` : 'N/A';

        row.targetDevice = this.deviceType(pubScheds[0].device_type);

        data.push(row);
      }

      return data;
    },
  },

  methods: {
    canExtend(index) {
      return moment().isSameOrBefore(moment(this.rowData[index].publishUntil, 'YYYY-MM-DD'));
    },

    getLookback(row) {
      return row.futurePubScheds.length > 0 &&
        row.futurePubScheds[0].config.lookback &&
        row.futurePubScheds[0].recur_type !== 'RENEW'
        ? row.futurePubScheds[0].config.lookback.join(' ')
        : 'N/A';
    },

    async handleCancelSched(index) {
      try {
        await this.$confirm(
          'This will cancel all future republishes. Are you sure?',
          'Cancel Republish Schedule?',
          {
            type: 'warning',
          },
        );

        const { audienceId, uuid } = this.rowData[index];
        await audienceApi.clearPublishSchedule(audienceId, uuid);
        this.$emit('schedule-change');
      } catch (e) {
        this.$reportError(e);
      }
    },

    async handleCommitExtend() {
      this.showExtendDialog = false;

      try {
        const { audienceId, uuid } = this.rowData[this.extendingPubIndex];
        await audienceApi.extendPublishSchedule(audienceId, uuid, {
          until: dateToString(this.newUntilDate),
        });
        this.$emit('schedule-change');
      } catch (e) {
        this.$reportError(e);
      }
    },

    handleExtend(index) {
      this.currentUntil = moment(this.rowData[index].publishUntil).toDate();
      this.newUntilDate = this.currentUntil;
      this.showExtendDialog = true;
      this.extendingPubIndex = index;
    },

    statusColor(publication) {
      switch (publication.status) {
        case 'PROCESSING':
          return '';
        case 'COMPLETE':
          return 'success';
        case 'ERROR':
          return 'danger';
        default:
          return 'info';
      }
    },

    deviceType(type) {
      switch (type) {
        default:
        case 'MOBILE':
          return 'Mobile';
        case 'DESKTOP':
          return 'Desktop/CTV';
        case 'BOTH':
          return 'Mobile & Desktop/CTV';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.pub-tables {
  display: flex;
  margin-top: 2em;

  .future-pubs {
    flex: 1;
  }

  .past-pubs {
    flex: 3;
    margin-left: 1em;
  }
}

.table-header {
  font-weight: bold;
}

.fa-check-circle {
  color: $--color-success;
}

.fa-exclamation-circle {
  color: $--color-danger;
}
</style>

<template>
  <div>
    <el-table
      :data="clones"
      :row-class-name="({row}) => row.started ? 'complete' : 'pending'"
    >
      <el-table-column
        label="Name"
        width="500"
      >
        <template slot-scope="{ row }">
          <router-link
            v-if="row.audience"
            :to="{ name: 'audience-detail', params: { id: row.audience.id }}"
          >
            {{ row.audience.name }}
          </router-link>
          <span v-else>{{ row | scheduledName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Count"
      >
        <template slot-scope="{ row }">
          <span v-if="row.audience">{{ row.audience.count | numeral }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Start Date"
        prop="config.start_date"
      />
      <el-table-column
        label="End Date"
        prop="config.end_date"
      />
      <el-table-column
        label="Created By"
      >
        <template slot-scope="{ row }">
          <span v-if="row.user">{{ row.user.first_name }} {{ row.user.last_name }}</span>
          <span v-else>N/A</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Scheduled"
      >
        <template slot-scope="{ row }">
          {{ row.scheduled | dateToString }}
        </template>
      </el-table-column>
      <el-table-column
        label="Created"
      >
        <template slot-scope="{ row }">
          <span v-if="row.audience">{{ row.audience.created_at | dateToString }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="{ row }">
          <span v-if="row.type">{{ row.type }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="button-row">
      <el-button
        v-if="showClear"
        slot="reference"
        size="small"
        type="danger"
        @click="clearClones"
      >
        <i class="fa fa-trash" />
        Cancel scheduled clones
      </el-button>
    </div>
  </div>
</template>

<script>
import { dateToString } from '@/helpers';
import moment from 'moment';

export default {
  filters: {
    dateToString,
    scheduledName(data) {
      return `${data.config.name} ${moment(data.config.start_date, 'YYYY-MM-DD').format('MMM DD YYYY')} - ${moment(data.config.end_date, 'YYYY-MM-DD').format('MMM DD YYYY')}`;
    },
  },

  props: {
    clones: { type: Array, default: () => [] },
  },

  computed: {
    showClear() {
      return this.clones.some(c => !c.started);
    },
  },

  methods: {
    clearClones() {
      const scheduled = this.clones.filter(c => !c.started).length;

      this.$confirm(
        `Are you sure you want to delete ${
          scheduled.toLocaleString()
        } scheduled clone${scheduled === 1 ? '' : 's'}?`,
        `Delete Scheduled Clone${scheduled === 1 ? '' : 's'}`,
      )
        .then(() => {
          this.$emit('clear-clones');
        })
        .catch(err => {
          if (err !== 'cancel') {
            this.$reportError(err);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

:deep( .el-table__row.pending td) {
  color: #acacac;
  font-style: italic;
}

.table-header {
  font-weight: bold;
}

.button-row {
  text-align: right;
  margin: 1em 0 0 0;
}
</style>

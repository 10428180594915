export default function(vm) {
  return Object.freeze([
    {
      command: 'audience',
      icon: 'network-wired',
      label: 'Create IP Audience',
      showAction: pixel => pixel.active,
    },
    {
      command: 'enable',
      icon: 'play-circle',
      label: 'Enable',
      showAction: pixel => !pixel.active,
    },
    {
      command: 'addData',
      icon: 'upload',
      label: 'Add Data',
      showAction: pixel => pixel.active,
    },
    {
      command: 'viewDetails',
      icon: 'clipboard-list',
      label: 'View Details',
      menuOnly: true,
    },
    {
      command: 'disable',
      icon: 'power-off',
      label: 'Disable',
      showAction: pixel => pixel.active,
    },
  ]);
}
